
import { type MetaFunction } from "@remix-run/react";

export const meta: MetaFunction = () => {
  return [
    { title: "EveOracle" },
    {
      property: "og:title",
      content: "EveOracle",
    },
    {
      name: "description",
      content: "The next-generation Eve Online intelligence toolkit!",
    },
  ];
};

export default function Index() {
  return null;
}

